<template>
  <div>
    <h3>31 May 2010 (0.185.8)</h3>
    <p>
      <u>Front End</u>
      <br>- The patcher’s executable has been re-named LEGOUniversePatcher.exe (the updater will try to update your shortcuts automatically).
      <br>- If a player’s local machine is not US English, the game will no longer attempt to create character names in a non-US English language.
      <br>- You will no longer see “Borontar” in the Namenator and will be able to create characters.
    </p>
    <p>
      <u>Chat</u>
      <br>- Word Suggest has been turned off.
    </p>
    <p>
      <u>Nimbus Station</u>
      <br>- In the concert scene, the moving platforms leading up to the Imagination Brick should now align correctly.
      <br>- In the concert scene, the game should no longer freeze when you are building.
    </p>
    <p>
      <u>Loot</u>
      <br>- If some other tester runs over your loot, they will no longer “collect” the loot for you.
    </p>
    <h3>29 May 2010 (0.185.5) </h3>
    <p>
      <u>Backpack</u>
      <br>- If you receive an item while your backpack is full, the game will now mail it to you instead.
    </p>
    <h3>28 May 2010 (0.185.4) </h3>
    <p>
      <u>Gnarled Forest</u>
      <br>- You will be able to properly re-spawn by the Waterfall Property launchpad.
    </p>
    <p>
      <u>Forbidden Valley</u>
      <br>- You are no longer able to jump off the level near Dragon Bill and become stuck.
    </p>
    <p>
      <u>Build Modes</u>
      <br>- Attempting to place a brick directly from your inventory outside of the build boundary should no longer cause crashes.
    </p>
    <h3>24 May 2010 (0.185.3) </h3>
    <p>
      <u>Avant Gardens</u>
      <br>- Survival Instance leaderboard now displays correctly.
    </p>
    <p>
      <u>Nimbus Station</u>
      <br>- The Foot Race leaderboard will appear correctly.
      <br>- The quickbuild by the Inventor’s Assistant will stop spitting imagination after a set amount of time.
    </p>
    <p>
      <u>User Interface</u>
      <br>- Mousing over the Faction Items now displays the correct UI.
      <br>- Opening and closing Speedchat will not create additional tabs.
      <br>- Steampunk Rocket Nosecone now has the correct mouseover info.
      <br>- The Basic Spear shows the correct mouseover info.
    </p>
    <p>
      <u>Race</u>
      <br>- There are no mission prerequisites to start the Gnarled Forest raceway.
      <br>- Players should now be prevented from becoming stuck on the loading screen or the cinematic sequence.
      <br>- If the loading screen is still visible that means that you are actually still loading.
      <br>- If the cinematic is playing, then you are waiting for other racers to load.
      <br>- When those racers are loaded, disconnected, or crash out, the race will immediately start with everyone who is ready.
    </p>
    <h3>21 May 2010 (0.185.2) </h3>
    <p>
      <u>Property</u>
      <br>- While in Model Mode, players can only equip hats.
      <br>- Dialogue loop for renting property has been fixed.
    </p>
    <p>
      <u>Pets</u>
      <br>- The pet menu should only appear if you have a pet deployed.
    </p>
    <h3>19 May 2010 (0.185.1) </h3>
    <p>
      <u>Avant Gardens</u>
      <br>- The monument race is reopened.
    </p>
    <p>
      <u>Forbidden Valley</u>
      <br>- Numb Chuck’s elevator is now moving.
    </p>
    <h3>18 May 2010 (0.185.0) </h3>
    <p>
      <u>Game Launcher</u>
      <br>- Presenting the new patcher!
    </p>
    <p>
      <u>Backpack</u>
      <br>- There are new icons for when your equipment bag, model bag, or both are full.
      <br>- When you attempt to pick up a dropped item that would need to go into s full bag, the bag icon will shake.
      <br>- If you accept another tester’s trade, but your bags are full, a pop-up will notify you that the item cannot fit in your bag.
      <br>- You can do brick searches in your Brick Bag.
    </p>
    <p>
      <u>Tooltips</u>
      <br>- The tooltips have been expanded.
    </p>
    <p>
      <u>Model Mode</u>
      <br>- Models that you name and/or describe will now display that custom name and/or description in your inventory.
      <br>- Objects can be interacted with, even while held over the Minifigure’s head.
      <br>- Minifigure movement slows down when holding a model.
      <br>- Models shouldn’t pierce through other models when placed.
    </p>
    <p>
      <u>Vendors</u>
      <br>- Vendors are able to trade much faster.
    </p>
    <p>
      <u>Moving Platforms</u>
      <br>- The moving platforms now run smoother.
    </p>
    <h3>17 May 2010 (0.184.10) </h3>
    <p>
      <u>Forbidden Valley</u>
      <br>- You should no longer be able to jump into the crevice scene by the gate.
    </p>
    <p>
      <u>Behaviors</u>
      <br>- “On Attack” behaviors will now function correctly on Reward Models.
    </p>
    <h3>14 May 2010 (0.184.8)</h3>
    <p>
      <u>Forbidden Valley</u>
      <br>- Deleting the Ninja Hood is no longer possible.
      <br>- Axe Stun Jump attack should now function like the Hammer Jump attack.
    </p>
    <p>
      <u>Game Launcher</u>
      <br>- You do not have the opportunity to run the game in German or UK English
      <br>- Pressing “Enter” while the game launcher is open no longer cause it to crash
    </p>
    <h3>13 May 2010 (0.184.7)</h3>
    <p>
      <u>Missions</u>
      <br>- Mission text should be easier to read.
    </p>
    <p>
      <u>Behaviors</u>
      <br>- Saving a behavior that is applied to a reward model no longer sends you back to the property screen.
      <br>- Behaviors will now remain saved on models you put in your inventory.
    </p>
    <p>
      <u>Pets</u>
      <br>- Use the Shift key to interact with the Pet Bouncers.
    </p>
    <p>
      <u>Shooting Gallery</u>
      <br>- The shooting Gallery reward sequence has been improved.
    </p>
    <p>
      <u>Forbidden Valley</u>
      <br>- You should no longer be able to fall out of the world
    </p>
    <h3>13 May 2010 (0.184.6)</h3>
    <p>
      <u>Avant Gardens</u>
      <br>- Equipping Numb Chuck’s Maelstrom Staff should no longer crash the client.
    </p>
    <p>
      <u>Gnarled Forest</u>
      <br>- You should no longer become stuck in terrain in Brig Rock
      <br>- The ninja mannequins now have a finished model, and not red boxes.
    </p>
    <p>
      <u>Forbidden Valley</u>
      <br>- “Teasing the Guards”-mission now have the correct mission text.
      <br>- Taming a panda pet now completes the mission to tame a panda.
      <br>- Getting stuck by the Dog Statue is not an issue anymore.
      <br>- Property launchers now have the correct signage.
    </p>
    <p>
      <u>Behaviors</u>
      <br>- Unnecessary blocks were removed.
      <br>- Adding a first behavior to a reward model now works.
    </p>
    <p>
      <u>Skills</u>
      <br>- Hammer and Axe double-jump attacks now work correctly.
    </p>
    <p>
      <u>Foot Race</u>
      <br>- Nimbus Station Foot Race is now active!
    </p>
    <h3>12 May 2010 (0.184.5)</h3>
    <p>
      <u>Model Mode</u>
      <br>- Attempting to pick up a model while “wobbling” and then exiting Model Mode quickly will no longer cause your Minifigure to become stuck.
    </p>
    <p>
      <u>Brick Mode</u>
      <br>- If you stay in Brick Mode with no activity for over 5 minutes, exiting Brick Mode will not make you crash.
    </p>
    <p>
      <u>Behaviors</u>
      <br>- Unsupported Action Blocks have been removed.
    </p>
    <p>
      <u>Nimbus Station</u>
      <br>- The launchpad from to Gnarled Forest is now surrounded by trees.
      <br>- The launchpad to Pet Cove is now accessible.
      <br>- Summary cinematic was added to the Gnarled Forest property launcher.
      <br>- You should no longer become stuck in the concert area entrance.
    </p>
    <p>
      <u>Gnarled Forest</u>
      <br>- The ninja mannequins now have a finished model.
    </p>
    <p>
      <u>Backpack</u>
      <br>- All inventory slots that appear are able to be used.
    </p>
    <p>
      <u>Pets</u>
      <br>- The Panda Pet is now tamable after you finish the Footrace.
      <br>- The Red Dragon Pet has become tamable.
    </p>
    <p>
      <u>Racing</u>
      <br>- Fixed an issue where you could get stuck waiting for a race map to load and never make it through.
      <br>- You no longer float above the car when racing.
    </p>
    <h3>11 May 2010 (0.184.4)</h3>
    <p>
      <u>Modular Build</u>
      <br>- You can no longer clone rocket parts.
    </p>
    <p>
      <u>Model Mode</u>
      <br>- You can now place models everywhere on your property.
    </p>
    <p>
      <u>Behaviors</u>
      <br>- The “Go Home” block is working.
    </p>
    <p>
      <u>Pets</u>
      <br>- You can now see your own pet names whether they have been moderated or not.
      <br>- The Shift key allows you to interact with Pet Bouncers, if you have a pet on the bouncer.
    </p>
    <p>
      <u>Nimbus Station</u>
      <br>- Flags now appear in Nimbus Station
    </p>
    <p>
      <u>Forbidden Valley</u>
      <br>- The Summary Screen shows the correct values.
    </p>
    <p>
      <u>Thinking Hat</u>
      <br>- Check out the new Thinking Hat!
    </p>
    <h3>7 May 2010 (0.184.3)</h3>
    <p>
      <u>Forbidden Valley</u>
      <br>- Forbidden Valley is open!
    </p>
    <p>
      <u>Pet Cove</u>
      <br>- You will now see approval or rejection messages for pet names.
    </p>
    <h3>6 May 2010 (0.184.2)</h3>
    <p>
      <u>Venture Explorer</u>
      <br>- Imagination orbs now reappear in the spaceship.
    </p>
    <p>
      <u>Racing</u>
      <br>- You can no longer start racing too soon!
    </p>
    <p>
      <u>Gnarled Forest</u>
      <br>- The crab pet area has been improved.
    </p>
    <p>
      <u>Backpack</u>
      <br>- The items you collect now stack properly
      <br>- Reward items from achievements and missions should now be visible upon receipt.
    </p>
    <h3>5 May 2010 (0.184.1)</h3>
    <p>
      <u>Nimbus Station</u>
      <br>- The Nimbus Station race instance now goes to the Gnarled Forest race map.
    </p>
    <p>
      <u>Pet Cove</u>
      <br>- The rocket cinematic for transition to Nimbus Station has been fixed.
      <br>- Fixed the image on the Pet Cove launchpad.
      <br>- Story plaque achievement now works.
    </p>
    <p>
      <u>Property</u>
      <br>- Property names have been updated.
    </p>
    <p>
      <u>Survival</u>
      <br>- The game now does a check to see if the Minigame is solo or group when the round starts.
    </p>
    <p>
      <u>Factions</u>
      <br>- Kit and Faction mission images have been updated.
    </p>
    <p>
      <u>Chat</u>
      <br>- Word suggest has been turned off.
    </p>
    <h3>4 May 2010 (0.184.0)</h3>
    <p>
      <u>Behaviors</u>
      <br>- Behaviors are here! Make your models do all sorts of cool stuff!
      <br>- Any model type you can drop you will be able to animate.
      <br>- We have increased the max brick counts for smashables/buildables on mid and high-end machines. Smashes should now be properly epic when your machine can handle it.
      <br>- The camera will once again properly fade LEGO models that have behaviors on them.
    </p>
    <p>
      <u>Pets</u>
      <br>- Pets will no longer follow you into pet restricted areas such as the Shooting Gallery.
      <br>- Your pet will not clip and fall through the balcony at Pet Cove.
      <br>- The warthog minigame will now shoe the white pieces.
      <br>- If a custom pet name is rejected, other pets can still be tamed.
    </p>
    <p>
      <u>Trade</u>
      <br>- You are no longer able to “sell” items of no value.
      <br>- You can’t sell bound items such as your rocket or models you have built.
      <br>- Vendors now allow you to buy back unique items.
      <br>- A full backpack no longer prevents you from completing missions that require inventory trading.
    </p>
    <p>
      <u>Friends</u>
      <br>- You can no longer add another player as a friend without them accepting your invitation first. If they accept, then it adds you to their list besides adding them to your list.
    </p>
    <p>
      <u>Nimbus Station</u>
      <br>- The speed reducing skunk stink now wears off after some time.
    </p>
    <p>
      <u>Build Modes</u>
      <br>- Display messages have been updated.
    </p>
    <p>
      <u>Model Mode</u>
      <br>- The appearance of picking up and dropping models has been updated.
      <br>- Models will no longer clip on top of each other if they are picked up and dropped quickly.
      <br>- You should no longer become stuck while modular building.
      <br>- Property models can now move through smashed models.
    </p>
    <p>
      <u>Brick Mode</u>
      <br>- If you remove your Thinking Hat while in Brick Mode your model will be saved correctly.
    </p>
    <p>
      <u>Property</u>
      <br>- If you are on someone else’s property, you will be given a warning if the property is being deleted and the area is being closed. After 60 seconds, you will be transferred back to the property launcher.
    </p>
    <p>
      <u>Racing</u>
      <br>- There is now an achievement to collect 10 hidden flags around the Gnarled Forest Race Track.
      <br>- Collectibles can now be collected while you are driving.
    </p>
    <p>
      <u>Gnarled Forest</u>
      <br>- The imagination required for most quickbuilds has been adjusted.
    </p>
    <p>
      <u>Nimbus Station</u>
      <br>- Mardolf’s Assistant in the Brick Annex will now convert Blue Imaginite Crystals into extra backpack space.
      <br>- There have been various mission changes…
    </p>
    <p>
      <u>Forbidden Valley</u>
      <br>- Forbidden Valley is now open!
    </p>
  </div>
</template>
